import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Select, Button, Spin } from 'antd';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactMarkdown from 'react-markdown';

const { Option } = Select;

// 상수 정의
const API_URL = 'https://wjkvbreqjpbdgqhcsyja.supabase.co/functions/v1/saju-analysis';

const NICKNAMES = {
  비견: '독립 투사',
  겁재: '경쟁을 즐기는 혁신가',
  식신: '안정적인 연구자',
  상관: '창의적인 설득가',
  편재: '민첩한 전략가',
  정재: '꼼꼼한 관리자',
  편관: '담대한 리더',
  정관: '보수주의자',
  편인: '통찰력 있는 철학자',
  정인: '학구적인 멘토'
};

// API 함수
const analyzeSaju = async (values) => {
  try {
    console.log("Sending request with values:", values);
    const formattedValues = {
      name: values.name,
      gender: values.gender,
      birthdate: values.birthdate,
      birthtime: values.birthtime,
      city: values.city,
      calendar_type: values.calendarType
    };

    const response = await axios.post(API_URL, formattedValues, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data.detail) || '서버에서 오류가 발생했습니다.');
    } else if (error.request) {
      throw new Error('서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.');
    }
    throw new Error('요청 중 오류가 발생했습니다.');
  }
};

// Styled components
const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  @media (max-width: 600px) {
    padding: 15px;
    margin-bottom: 15px;
  }
`;

const AppWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Nanum Gothic', Arial, sans-serif;
  background-color: #f0f0f0;
  line-height: 1.6;

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const StyledForm = styled(Form)`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 10px;
  }

  .ant-input, .ant-select {
    font-size: 16px;
  }
`;

const Section = styled(Card).attrs({ as: 'section' })``;

const Highlight = styled.span`
  background-color: #3498db;
  color: white;
  padding: 3px 6px;
  border-radius: 3px;
  font-weight: bold;

  @media (max-width: 600px) {
    padding: 2px 4px;
  }
`;

const ErrorWrapper = styled.div`
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;

  ul {
    margin: 0;
    padding-left: 20px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: #333;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 50px;
  height: auto;
  margin-right: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

const PlaceholderText = styled.small`
  color: #666;
  margin-left: 4px;
`;

const StyledCard = styled(Card)``;

const JobCategory = styled.div`
  margin-bottom: 15px;
`;

const Footer = styled.footer`
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: #666;
`;

const FooterLink = styled(Link)`
  color: #1890ff;
  margin: 0 5px;
  
  &:hover {
    color: #40a9ff;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  
  &:hover {
    background-color: #c0392b;
    color: white;
  }

  @media (max-width: 600px) {
    padding: 12px 20px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${props => props.isVisible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 95%;
  max-width: 800px;
  text-align: center;
  position: relative;

  #coupang-ad-container {
    margin: 20px auto;
    width: 100%;
    max-width: 700px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 15px;
    
    #coupang-ad-container {
      min-height: 300px;
    }
  }
`;

const AdContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  min-height: 100px;  // 배너 높이에 맞춰 최소 높이 설정
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
`;

const CoupangAdModal = ({ isVisible, onClose }) => {
  // 페이지 가시성 변경 감지
  useEffect(() => {
    if (isVisible) {
      // 페이지 숨김/표시 이벤트 리스너
      const handleVisibilityChange = () => {
        if (document.hidden) {
          // 페이지가 숨겨졌을 때 (사용자가 쿠팡으로 이동)
          // 약간의 지연 후 다음 단계 진행
          setTimeout(() => {
            onClose();
          }, 500);
        }
      };

      // 이벤트 리스너 등록
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // cleanup
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isVisible, onClose]);

  useEffect(() => {
    if (isVisible) {
      const container = document.getElementById('coupang-ad-container');
      if (container) {
        container.innerHTML = '';
        
        const script = document.createElement('script');
        script.innerHTML = `
          new PartnersCoupang.G({
            "id":818420,
            "template":"banner",
            "trackingCode":"AF6030228",
            "width":"200",
            "height":"200"
          });
        `;
        
        const sdkScript = document.createElement('script');
        sdkScript.src = 'https://ads-partners.coupang.com/g.js';
        sdkScript.async = true;
        
        sdkScript.onload = () => {
          container.appendChild(script);
        };
        
        container.appendChild(sdkScript);
        
        return () => {
          if (container) {
            container.innerHTML = '';
          }
        };
      }
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ModalOverlay isVisible={isVisible}>
      <ModalContent>
        <Title>잠깐만요! 🎁</Title>
        <Description>
          쿠팡 상품을 확인하시고<br/>
          무료로 상세한 사주 분석을 받아보세요!
        </Description>
        <div id="coupang-ad-container"></div>
      </ModalContent>
    </ModalOverlay>
  );
};

// Components
const InputForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const validateBirthdate = (_, value) => {
    if (!value) {
      return Promise.reject('생년월일을 입력해주세요');
    }
    if (!/^\d{8}$/.test(value)) {
      return Promise.reject('생년월일은 8자리 숫자로 입력해주세요 (예: 19910101)');
    }
    return Promise.resolve();
  };

  const validateBirthtime = (_, value) => {
    if (!value) {
      return Promise.reject('태어난 시간을 입력해주세요');
    }
    if (!/^\d{4}$/.test(value)) {
      return Promise.reject('태어난 시간은 4자리 숫자로 입력해주세요 (예: 0700)');
    }
    const hours = parseInt(value.substring(0, 2), 10);
    const minutes = parseInt(value.substring(2), 10);
    if (hours >= 24 || minutes >= 60) {
      return Promise.reject('올바른 시간을 입력해주세요 (00:00 ~ 23:59)');
    }
    return Promise.resolve();
  };

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      birthdate: `${values.birthdate.substring(0, 4)}-${values.birthdate.substring(4, 6)}-${values.birthdate.substring(6)}`,
      birthtime: `${values.birthtime.substring(0, 2)}:${values.birthtime.substring(2)}:00`
    };
    onSubmit(formattedValues);
  };

  return (
    <StyledForm form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item 
        name="name" 
        label="이름" 
        rules={[{ required: true, message: '이름을 입력해주세요' }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item 
        name="gender" 
        label="성별" 
        initialValue="female"
        rules={[{ required: true, message: '성별을 선택해주세요' }]}
      >
        <Select>
          <Option value="male">남자</Option>
          <Option value="female">여자</Option>
        </Select>
      </Form.Item>

      <Form.Item 
        name="birthdate" 
        label={<>생년월일 <small>(예: 19910101)</small></>}
        rules={[{ validator: validateBirthdate }]}
      >
        <Input type="number" pattern="\d*" inputMode="numeric" />
      </Form.Item>

      <Form.Item 
        name="birthtime" 
        label={<>태어난 시간 <small>(예: 0900)</small></>}
        rules={[{ validator: validateBirthtime }]}
      >
        <Input type="number" pattern="\d*" inputMode="numeric" />
      </Form.Item>

      <Form.Item 
        name="city" 
        label="태어난 도시" 
        initialValue="서울"
        rules={[{ required: true, message: '태어난 도시를 선택해주세요' }]}
      >
        <Select>
          <Option value="서울">서울 부근</Option>
          <Option value="부산">부산 부근</Option>
          <Option value="대구">대구 부근</Option>
          <Option value="인천">인천 부근</Option>
          <Option value="광주">광주 부근</Option>
          <Option value="대전">대전 부근</Option>
          <Option value="울산">울산 부근</Option>
        </Select>
      </Form.Item>

      <Form.Item 
        name="calendarType" 
        label="양력/음력" 
        initialValue="solar"
        rules={[{ required: true, message: '양력/음력을 선택해주세요' }]}
      >
        <Select>
          <Option value="solar">양력</Option>
          <Option value="lunar">음력</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          분석하기
        </Button>
      </Form.Item>
    </StyledForm>
  );
};


// Result Components
const PersonalitySection = ({ result }) => {
  return (
    <Section>
      <h2>성격 및 특성</h2>
      <p><strong>{result.name}님은</strong> {result.analysis_result.day_character}</p>
      <p><strong>{result.name.slice(1)}님은</strong> {result.analysis_result.day_pillar_character}</p>
    </Section>
  );
};

const JobCapabilitiesSection = ({ result }) => {
  const capabilities = result.analysis_result.job_capabilities || {};
  const dayShichen = result.saju_info.day_zhi_shichen.split('(')[0];

  const sortedShichen = useMemo(() => 
    [dayShichen, ...Object.keys(capabilities).filter(s => s !== dayShichen)],
    [dayShichen, capabilities]
  );

  return (
    <Section>
      <h2>직업 역량</h2>
      {sortedShichen.map((shichen, index) => (
        <div key={shichen}>
          <h3>{`${String.fromCharCode(65 + index)}. ${NICKNAMES[shichen]}`}</h3>
          <ul>
            {(capabilities[shichen] || []).map((capability, index) => (
              <li key={index}>{capability}</li>
            ))}
          </ul>
        </div>
      ))}
    </Section>
  );
};

const JobRecommendationsSection = ({ result }) => {
  const recommendations = result.analysis_result.job_recommendations || {};
  const dayShichen = result.saju_info.day_zhi_shichen.split('(')[0];
  
  const sortedShichen = useMemo(() => 
    [dayShichen, ...Object.keys(recommendations).filter(s => s !== dayShichen)],
    [dayShichen, recommendations]
  );

  return (
    <Section>
      <h2>추천 직업군</h2>
      {sortedShichen.map((shichen) => (
        <div key={shichen} className="job-category">
          <h3><Highlight>{NICKNAMES[shichen]}</Highlight></h3>
          {Object.entries(recommendations[shichen] || {}).map(([category, jobs]) => (
            <div key={`${shichen}-${category}`}>
              <p>
                <strong>{category}:</strong>{' '}
                {Array.isArray(jobs) ? jobs.join(', ') : jobs}
              </p>
            </div>
          ))}
        </div>
      ))}
    </Section>
  );
};

const ConsultationSection = () => {
  const [modalImage, setModalImage] = useState(null);

  const ReviewImage = styled.img`
    width: 180px;
    height: 180px;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  `;

  const settings = useMemo(() => ({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px'
        }
      }
    ]
  }), []);

  const reviews = useMemo(() => [
    { id: 1, image: '/review1.jpg' },
    { id: 2, image: '/review2.jpg' },
    { id: 3, image: '/review3.jpg' },
    { id: 4, image: '/review4.jpg' },
    { id: 5, image: '/review5.jpg' }
  ], []);

  return (
    <Card>
      <h2>유선상담 신청하기</h2>
      
      <h3>상담 후기</h3>
      <Slider {...settings}>
        {reviews.map((review) => (
          <div key={review.id}>
            <ReviewImage 
              src={review.image} 
              alt={`Review ${review.id}`} 
              onClick={() => setModalImage(review.image)}
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
      
      <h3>상담을 통해 얻을 수 있는 정보</h3>
      <ul>
        <li>새로운 일을 시작하기에 가장 좋은 시기</li>
        <li>현재 하고 있는 일의 성과가 나는 좋은 시기</li>
        <li>불리한 시기에 대비하여 불운을 비켜가는 방법</li>
        <li>자기한테 유리한 궁합, 연애 운세 흐름 및 본인한테 어울리는 진짜 이상형 확인</li>
        <li>사업, 장사 등 금전과 관련된 운세의 흐름</li>
      </ul>
      
      <h3>희망사주 상담 차별점</h3>
      <ul>
        <li>당근 마켓 최고수준 만족도</li>
        <li>내담자 사주의 한계를 규정짓지 않고 내담자가 현재보다 더 나은 삶을 살 수 있도록 도움을 줄 수 있는 상담을 진행</li>
        <li>현대명리학으로 옛날이 아닌 현시대에 알맞은 솔루션 제공</li>
      </ul>
      
      <CTAButton 
        href="https://www.daangn.com/kr/business-profiles/%ED%9D%AC%EB%A7%9D%EC%82%AC%EC%A3%BC-43b2570df1c5496da0ffc8b611ae67f0/"
        target="_blank"
        rel="noopener noreferrer"
      >
        방문하기
      </CTAButton>

      {modalImage && (
        <Modal onClick={() => setModalImage(null)}>
          <ModalImage src={modalImage} alt="Full size review" />
        </Modal>
      )}
    </Card>
  );
};

const ErrorMessage = ({ message }) => {
  let errorContent;
  try {
    errorContent = JSON.parse(message);
    if (Array.isArray(errorContent)) {
      return (
        <ErrorWrapper>
          <ul>
            {errorContent.map((err, index) => (
              <li key={index}>{err.msg}</li>
            ))}
          </ul>
        </ErrorWrapper>
      );
    }
  } catch (e) {
    errorContent = message;
  }
  
  return <ErrorWrapper>{errorContent}</ErrorWrapper>;
};

const LoadingPage = () => (
  <LoadingWrapper>
    <Spin size="large" />
    <LoadingText>좀 더 정확하게 분석중입니다.</LoadingText>
  </LoadingWrapper>
);

const ResultPage = ({ result, onBack }) => (
  <>
    <h1>{result.name}님의 사주 분석 결과</h1>
    <PersonalitySection result={result} />
    <JobCapabilitiesSection result={result} />
    <JobRecommendationsSection result={result} />
    <ConsultationSection />
    <Button onClick={onBack} style={{ marginBottom: '20px' }}>뒤로 가기</Button>
  </>
);

const MainApp = () => {
  const [analysisResult, setAnalysisResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [pendingAnalysis, setPendingAnalysis] = useState(null);

  const handleSubmit = async (values) => {
    setPendingAnalysis(values);
    setShowAd(true);
  };

  const handleAdClose = async () => {
    setShowAd(false);
    setLoading(true);
    
    try {
      const result = await analyzeSaju(pendingAnalysis);
      setAnalysisResult(result);
      setError(null);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
      setPendingAnalysis(null);
    }
  };

  return (
    <AppWrapper>
      {error && <ErrorMessage message={error} />}
      {loading ? (
        <LoadingPage />
      ) : !analysisResult ? (
        <>
          <TitleWrapper>
            <Logo src="/logo.png" alt="로고" />
            <h1>희망 사주 분석</h1>
          </TitleWrapper>
          <InputForm onSubmit={handleSubmit} />
          <CoupangAdModal 
            isVisible={showAd} 
            onClose={handleAdClose}
          />
          <Footer>
            <p>누리스마일</p>
            <p>대표이사 : 김현근 ㅣ사업자등록번호 173-39-01229</p>
            <p>주소 : 경기도 용인시 수지구 대지로36, 신흥빌딩 3층 302</p>
            <p>
              <FooterLink to="/TermsPage">이용약관</FooterLink> ㅣ
              <FooterLink to="/PrivacyPage">개인정보처리 방침</FooterLink>ㅣ
              고객센터 : nurismile2022@gmail.com
            </p>
            <p>Copyright © 2024 누리스마일 - All right reserved</p>
          </Footer>
        </>
      ) : (
        <ResultPage 
          result={analysisResult} 
          onBack={() => {
            setAnalysisResult(null);
            setError(null);
          }}
        />
      )}
    </AppWrapper>
  );
};

const TermsPage = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/terms.md')
      .then(response => response.text())
      .then(text => setContent(text))
      .catch(error => console.error('Error loading terms:', error));
  }, []);

  return (
    <AppWrapper>
      <Card>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Card>
    </AppWrapper>
  );
};

const PrivacyPage = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/privacy.md')
      .then(response => response.text())
      .then(text => setContent(text))
      .catch(error => console.error('Error loading privacy policy:', error));
  }, []);

  return (
    <AppWrapper>
      <Card>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Card>
    </AppWrapper>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainApp />} />
      <Route path="/TermsPage" element={<TermsPage />} />
      <Route path="/PrivacyPage" element={<PrivacyPage />} />
    </Routes>
  </Router>
);

export default App;